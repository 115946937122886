import React from "react";
import { Typography } from '@material-ui/core';
// Reusable components
import JobCard from "./components/JobCard";
// Style
import './style.scss';

const MY_JOBS = [
   {
     position: "Frontend Engineer",
     company: "Pattern",
     contractType: "Full-time",
     start: "01-14-2020",
     end: "08-30-2020",
     current: false,
     location: "Vancouver, Canada",
     description: "As one of two Frontend Engineers, I collaborated closely with the design, engineering and operations teams to plan, implement and maintain both client-facing and internal web applications. Technologies I used include React, TypeScript, Sass, Material-UI and React-Admin - as well as the basic web technologies (HTML, CSS, and JavaScript).",
     technologies: ["React", "TypeScript", "react-redux", "material-ui", "react-admin", "CSS3", "HTML5", "Trello", "Zoom", "Slack", "Visual Studio Code", "Scrum"],
     techSummary: ["React (in TypeScript)", "React Redux", "Material-UI", "React Admin"],
     details: [
       "Fixed critical bugs in the product.",
       "Implemented features that improve the customer's experience on the site.",
       "Proposed improvements in coding style, code review process and use of redux."
     ],
     iconURI: "https://i.ibb.co/rHNRDYH/1605746369402.jpg"
   },
   {
     position: "Software Engineer Intern",
     company: "LoginRadius",
     contractType: "Full-time",
     start: "09-03-2018",
     end: "08-29-2019",
     current: false,
     location: "Vancouver, Canada",
     description: "Collaborated with other Software Engineers to create, improve and test features of the company's main product, using the Scrum agile methodology. Technologies I used include React, TypeScript, AngularJS, NodeJS, Azure DevOps, Jira (Atlassian), Confluence (Atlassian), and nginx - as well as the basic web technologies (HTML, CSS, and JavaScript).",
     technologies: ["React", "react-redux", "redux-saga", "AngularJS", "TypeScript", "nginx", "Node.js", "Jira", "Confluence", "BugZilla", "Scrum"],
     techSummary: ["React", "Redux-saga", "AngularJS", "Jira"],
     details: [
       "Successfully worked remotely for 3 months in Colombia.",
       "Quickly learned new technologies to meet my responsabilities (e.g. RequireJS).",
       "Refactored a Node.js monolithic application to follow a microservices architecture.",
       "Rewrote AngularJS code in React to improve product's performance and maintainability.",
       "Used redux-saga and axios for handlings side-effects (network requests) of a React application.",
       "Wrote and executed code in the Nigthwatch.js framework to automate UI testing.",
       "Noticed and digilently reported bugs - one of which was a high security vulnerability.",
       "Developed 2 demo applications using the Preact and Aurelia frameworks. Wrote installation guides for users of the applications.",
       "Wrote code in JavaScript to enable data synchronization with third-party provider Matomo (previously Piwick).",
       "Managing code using Microsoft Azure DevOps",
       "Tracking work using Jira.",
       "Communicating with the team using Gmail and Google Hangouts.",
       "Writing documentation (internal and external) in Confluence.",
       "Managing bugs on all of Microsoft Azure DevOps, BugZilla and Jira.",
       "Developed 2 demo applications using the Preact and Aurelia frameworks. Wrote installation guides for users of the applications.",
       "Refactored a demo written by another developer in the React framework, fixing bugs and removing unnecessary code. Updated the demo's documentation.",
       "Noticed and fixed grammar and spelling in mistakes in documentation written for clients.",
       "Used HTML, CSS and JavaScript to clone the UI and behaviour of another company's login page. (Training project)",
       "Refactored a multiple-page application into a single-page application. (Training project)",
       "Wrote and posted Engineering blog posts to the company's Wordpress site to provide supporting documents to clients.",
       "Wrote code in Microsoft Visual Studio Code IDE."
     ],
     iconURI: "https://i.ibb.co/9HhYQp7/login-radius.png"
   },
   {
     position: "Database Systems (TA)",
     company: "Langara College",
     contractType: "Part-time",
     start: "05-06-2017",
     end: "08-25-2017",
     current: false,
     location: "Vancouver, Canada",
     description: "Marker for the course CPSC 2221: Database Systems. The course focuses on the foundational concepts for the design, implementation and use of relational database applications. As a term project, students used a XAMP stack to create a real-world application that applies the core concepts of the course.",
     technologies: ["HTML", "PHP", "MySQL", "XAMPP"],
     techSummary: ["HTML", "PHP", "MySQL", "XAMPP"],
     details: [
       "Providing feedback on sprint deliverables to build a database-driven web application.",
       "Providing feedback regarding good programming practices, such as internal documentation, external documentation and coding style.",
       "Evaluating complexity of questions to assign grade value.",
       "Reporting hours worked on a bi-weekly basis using Langara College’s online time sheet system."
     ],
     iconURI: "https://i.ibb.co/ChgX3vF/langara.png"
   },
   {
     position: "Calculus I (TA)",
     company: "Langara College",
     contractType: "Part-time",
     start: "04-06-2016",
     end: "12-25-2016",
     current: false,
     location: "Vancouver, Canada",
     description: "Marker for the course MATH 1174: Calculus I - Economic and Business Applications.",
     technologies: ["Langara's Employee Board", "Gmail suite"],
     techSummary: ["Langara's Employee Board", "Gmail suite"],
     details: [
       "Marking weekly homework packages from classes consisting of forty one (41) to seventy one (71) students.",
       "Providing concise feedback on mathematical procedures to each of forty (41) to seventy one (71) students weekly.",
       "Evaluating complexity of questions to assign grade value.",
       "Keeping records of students’ scores and submitting them at the end of the semester to supervisor.",
       "Making the appropriate corrections in a student’s records when an error has been made.",
       "Reporting hours worked on a bi-weekly basis using Langara College’s online time sheet system."
     ],
     iconURI: "https://i.ibb.co/ChgX3vF/langara.png"
   },
   {
     position: "Facilitator",
     company: "Immigrant Services Society of BC",
     contractType: "Part-time",
     start: "01-06-2016",
     end: "05-25-2016",
     current: false,
     location: "Coquitlam, Canada",
     description: "Facilitator of an iteration of the MY Circle program. Provided leadership and peer support skills workshops for immigrant youth new to Canada. Some responsibilities included activity planning, public speaking and retroactive staff meetings.",
     technologies: ["Gmail suite", "Microsoft Word"],
     techSummary: ["Gmail suite", "Microsoft Word"],
     details: [
       "Preparing workshops individually, following a guidance booklet provided by the organization.",
       "Meeting weekly with supervisor and other two co-facilitators to discuss, plan and assign responsibilities for the next session.",
       "Facilitating sessions weekly for a group of twenty four (24) immigrant youth alongside two (2) other co-facilitators for eight (8) hours.",
       "Debriefing for one (1) to one and a half (1.5) hours about the day’s session.",
       "Using inter-personal and cross-cultural communication skills to address issues promptly and appropriately, individually or with the help of coworkers - as demanded by the situation.",
       "Applying cross-cultural communication strategies to create a space where people with various personality types and culture can collaborate and share their knowledge.",
       "Facilitating dynamic group activities to increase energy of the group, achieving more participation in later activities.",
       "Inquiring about foreign etiquette in order to effectively work alongside people from diverse cultural backgrounds."
     ],
     iconURI: "https://i.ibb.co/KyRrd5b/issOfBC.png"
   }
 ];

export default () => {
   return (
      <div id="experience-wrapper">
         <Typography 
          className="section-title"
          variant="h1"
         >
            Experience
         </Typography>
         {MY_JOBS.map(job => <JobCard key={job.start} job={job} />)}
      </div>
   );
};