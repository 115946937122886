import React from 'react';
import { Typography, Avatar } from '@material-ui/core';
// Style
import './style.scss';

class About extends React.Component {
  render () {
    return (
      <div id="about-wrapper">
        <Typography 
          className="section-title"
          variant="h1"
        >
          About
        </Typography>
        <Avatar
          alt="A picture of me at Stanley Park in December of 2019."
          classes={{
            root: "headshot-avatar"
          }}
          src="https://i.ibb.co/pK8RSzH/Photo-in-downtown-Vancouver.jpg"
          variant="circle"
        />
        <Typography
          variant="subtitle1"
          color="textSecondary"
        >
          I am a Frontend Engineer in Vancouver, (beautiful) British Columbia. 
          Most of the time you'll find me talking to designers, other engineers, and business staff to translate
          business logic into web experiences users will love. 
          I am passionate about performant and elegant UI/UX designs. 
          Click on the "Get in touch" button if you want to create something awesome together; you will find a link to&nbsp;
            <a 
              id="my-linked-in-link"
              href="https://www.linkedin.com/in/rubenprograms/"
              target="_blank"
              rel="noopener noreferrer"
            >
            my LinkedIn profile
            </a>, among others.
          <br />
          Talk soon!
        </Typography>
      </div>
    )
  }
}

export default About;