import React, { useState } from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
// Subcomponents
import AppBar from './components/AppBar';
// Scenes
import Updates from '../scenes/Updates';
import About from '../scenes/About';
import Experience from '../scenes/Experience';
// Style
import css from '../scss/main.module.scss';
import './style.scss';

const THEME = createMuiTheme({
  palette: {
    primary: {
      main: css.blue,
      contrastText: css.grey
    },
    secondary: {
      main: css.colorSecondary
    }
  },
  typography: {
    fontFamily: [
      "Montserrat", "sans-serif"
    ].join(","),
    button: {
      fontFamily: [
        "Montserrat", "sans-serif"
      ].join(","),
      fontSize: "1rem",
      textTransform: "none"
    }
  },
  shape: {
    borderRadius: 16
  }
});

export default () => {
  const [contentVisible, setContentVisible] = useState(false);
  setTimeout(() => {
    setContentVisible(true);
  }, 400);
  return (
    <ThemeProvider theme={THEME}>
      <div id="app-wrapper">
        <Router>
          <AppBar />
          <main className={contentVisible ? "visible" : ""}>
            <Switch>
              <Route exact match path="/updates" component={Updates} />
              <Route exact match path="/about" component={About} />
              <Route exact match path="/experience" component={Experience} />
              <Redirect to="/updates"/>
            </Switch>
          </main>
        </Router>
      </div>
    </ThemeProvider>
  )
};