import React from "react";
import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
// Reusable components
import ButtonCustom from '../../../../components/ButtonCustom';
// Data
import { Job } from "../../../../Data/jobs";
// Utils
import { formatDate } from "../../../../utils/dates";
// Style
import "./style.scss";

interface Props {
  job: Job;
};

export default (props: Props) => {
  const [showDetails, setShowDetails] = React.useState(false);
  const job = props.job;

  return (
    <div className="job-card">
      {showDetails && (
        <Dialog
          className="job-card-dialog"
          open
          onClose={() => { setShowDetails(false) }}
        >
          <DialogTitle className="job-card-dialog-title">
            <Grid
              container
              justify="space-between"
              alignItems="center"
            >
              <Typography
                variant="h5"
                color="primary"
              >
                {job.position}
              </Typography>
              <IconButton edge="end">
                <CloseIcon onClick={() => { setShowDetails(false) }} />
              </IconButton>
            </Grid>

            <Grid container>
              <figure className="job-icon-figure">
                <img
                  src={job.iconURI}
                  className="job-icon"
                  alt={`${job.company}'s logo`}
                />
                <figcaption>
                  <Typography
                    color="primary"
                    align="center"
                  >
                    {job.company}
                  </Typography>
                </figcaption>
              </figure>
            </Grid>
            <Grid container>
              <Typography display="inline">
                {job.description}
              </Typography>
            </Grid>

          </DialogTitle>
          <DialogContent className="job-card-dialog-content">
            <Grid container>
              {job.details.map((detail: string) => {
                return (
                  <div className="job-card-dialog-detail-wrapper">
                    <Typography>
                      {detail}
                    </Typography>
                  </div>
                )
              })}
            </Grid>
          </DialogContent>
          <DialogActions className="job-card-dialog-actions">
            <Typography
              variant="h6"
              color="primary"
            >
              {formatDate(job.start)} - {job.current ? "Current" : formatDate(job.end)}
            </Typography>
          </DialogActions>
        </Dialog>
      )}
      <div className="job-card-content-wrapper">
        <figure className="job-icon-figure">
          <img
            src={job.iconURI}
            className="job-icon"
            alt={`${job.company}'s logo`}
          />
          <figcaption><Typography>{job.company}</Typography></figcaption>
        </figure>
        
        <div className="job-details-wrapper">
          <Typography className="job-position">{job.position}</Typography>  
          <Typography className="job-company">{job.company}</Typography>
          <Typography
            className="job-date-and-location"
            variant="body2"
            gutterBottom
          >
              {formatDate(job.start)}&nbsp;-&nbsp;{job.current ? "Current" : formatDate(job.end)}
              &nbsp;
              &middot;
              &nbsp;
              {job.location}
          </Typography>
          <Typography 
            className="job-description"
            color="textSecondary"
            gutterBottom
          >
            {job.description}
          </Typography>

        </div>
        
        
        {/* 
        <Typography className="technologies">Main Technologies:</Typography>
        <ul className="main-technologies">
          {job.techSummary.map((tech: string) => {
            return (
              <li><Typography variant="body2">{tech}</Typography></li>
            )
          })}
        </ul> */}
      </div>
      {/* <div className="job-card-button-wrapper">
        <ButtonCustom
          text="LEARN MORE"
          textColor={css.colorWhite}
          backgroundColor={css.colorPrimary}
          onClick={() => { setShowDetails(true) }}
          variant="contained"
          fullWidth
        />
      </div> */}
    </div>
  );
};