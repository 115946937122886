import React from 'react';
import { Typography } from '@material-ui/core';
// Style
import './style.scss';

const UPDATES = [
  {
    title: "I've completed my website redesign!",
    date: "Fri Apr 09 2021 17:17:20 GMT-0700 (Pacific Daylight Time)",
    content: `
      I have completed the redesign! I saw just now that in my previous update I did not mention my motivation for it.
      After talking to recruiters about my work experience, I realized that I had never built a side project that was as
      sophisticated as the web applications I had implemented at my previous companies. Whenever recruiters asked me
      for a link to a web application I had built, I would give them a link to my personal website, and I'd immediately
      excuse myself, saying, 'but my website doesn't reflect the complexity of the applications I can build! It's just
      that I haven't had time to make it as nice!' And that was true, I had been too busy - occasionaly watching Netflix - 
      to devote enough time to my personal projects. That, of course, wasn't good - no recruiter ever told me but I knew I had
      to do better. So I planned this redesign, the third one I've ever done to my website. Although the logic of the website 
      is not out of this world, the visual and UX aspect of it gets closer to reflect what I can accomplish when building a web
      app: I have made it responsive so it is usable on mobile devices (try it on your phone and let me know what you think!),
      included smooth animations and opted for a minimalist design so visitors feel at ease. There are ways I can improve over the
      new design, of course, mainly adding more content and making it more 'fun.' I will get to that in due time and post an update here, I'm sure.
      Until then, nos vemos!
    `
  },
  {
    title: "A third redesign!",
    date: "Mon Apr 05 2021 17:32:51 GMT-0700 (Pacific Daylight Time)",
    content: `
      As I write this update, I am completing the third redesign I have put my website through. 
      The idea of having a page for updates ocurred to me right before beginning the current redesign, 
      so naturally I hadn't written any updates for the website prior to this one. It is a bit weird this 
      is the first update considering it is the result of another update. Anyway, writing this entry ensures 
      I will have at least two entries on this page when I push all the updates: the next update will announce 
      I have completed the redesign! See you then.
    `
  }
]

class Updates extends React.Component {
  render () {
    return (
      <div id="updates-wrapper">
        <Typography 
          className="section-title"
          variant="h1"
        >
          Updates
        </Typography>
        <div id="update-entries-wrapper">
          {UPDATES.map((update) => {
            return (
              <section 
                key={update.date}
                className="update-entry-section"
              >
                <Typography
                  variant="h1"
                  className="update-entry-title"
                >
                  {update.title}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className="update-entry-date"
                >
                  {(new Date(update.date)).toDateString()}
                </Typography>
                <Typography
                  variant="body1"
                  className="update-entry-content"
                  color="textSecondary"
                >
                  {update.content}
                </Typography>
              </section>
            )
          })}
        </div>
      </div>
    )
  }
}

export default Updates;