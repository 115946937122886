import React from "react";
import { Link, withRouter } from 'react-router-dom';
import { Typography, Button, IconButton } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import Dialog from './components/Dialog/index';
// Style
import "./style.scss";

interface Props {
  // react-router-dom props
  match: any;
  location: any;
  history: any;
}
interface State {
  visible: boolean;
  expandNavBar: boolean;
  showGetInTouchDialog: boolean;
}
class AppBar extends React.Component<Props, State> {

  static ROUTES: string[] = [ "Updates", "About", "Experience"];

  constructor(props: Props) {
    super(props);
    this.state = { 
        expandNavBar: false,
        showGetInTouchDialog: false,
        visible: false
    }; 
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ visible: true});
    }, 250);
    
  }

  render () {
    const pathname = this.props.location.pathname || "";
    const { showGetInTouchDialog, expandNavBar, visible } = this.state;
    return (
      <div id="app-bar" className={visible ? "visible" : ""}>
        {showGetInTouchDialog && <Dialog onClose={() => { this.setState({ showGetInTouchDialog: false }) }}/>}
        <img 
          id="my-headshot"
          alt="Headshot of Ruben"
          src="https://i.ibb.co/pK8RSzH/Photo-in-downtown-Vancouver.jpg"
          tabIndex={-1}
        />
        <section id="nav-and-button-wrapper">
          {expandNavBar && 
            <div 
              id="nav-bar-background" 
              onClick={(e) => { 
                this.setState({ expandNavBar: false });
                e.stopPropagation();
              }}
            />
          }
          <nav 
            id="nav-bar"
            className={expandNavBar ? "expand-nav-bar" : ""}
          >
            {AppBar.ROUTES.map((route: string) => {
              const routeLowerCase = route.toLocaleLowerCase();
              return (
                <Link 
                  key={routeLowerCase}
                  to={routeLowerCase}
                  className={"nav-bar-link" + (pathname.indexOf(routeLowerCase) !== -1 ? " active-nav-bar-link" : "")}
                  onClick={() => { this.setState({ expandNavBar: false }) }}
                >
                  <Typography className="nav-bar-link-text">{route}</Typography>
                </Link>
              )
            })}
          </nav>
          <Button 
            id="get-in-touch-button"
            color="primary"
            variant="contained"
            onClick={() => { this.setState({ showGetInTouchDialog: true }) }}
          >
            <Typography id="get-in-touch-text">
              Get in touch
            </Typography>
          </Button>
          <IconButton 
            id="menu-icon-wrapper"
            onClick={() => {
              this.setState((state: State) => ({ expandNavBar: !state.expandNavBar }));
            }}
          >
            <MenuIcon id="menu-icon"/>
          </IconButton>
        </section>
      </div>
    )
  }
}

export default withRouter(AppBar);