import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Link, Button } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import InstagramIcon from '@material-ui/icons/Instagram';
// Style
import './style.scss';

interface Props {
  onClose: () => void;
}

const CHANNELS = [
  { name: "LinkedIn", icon: <LinkedInIcon className="channel-link-icon"/>, link: "https://www.linkedin.com/in/rubenprograms/" },
  { name: "Twitter", icon: <TwitterIcon className="channel-link-icon"/>, link: "https://twitter.com/rubenprograms" },
  { name: "GitHub", icon: <GitHubIcon className="channel-link-icon"/>, link: "https://github.com/rubenprograms" },
  { name: "Instagram", icon: <InstagramIcon className="channel-link-icon"/>, link: "https://www.instagram.com/rubenprograms/" }
]

export default (props: Props) => {
  
  return (
    <Dialog 
      open
      onClose={props.onClose}
      classes={{paper: "get-in-touch-dialog-paper"}}
    >
      <DialogTitle 
        id="get-in-touch-dialog-title"
        disableTypography
      >
        <Typography 
          id="get-in-touch-title"
          color="secondary"
          variant="h1"
        >
          Get in touch!
        </Typography>
      </DialogTitle>
      <DialogContent id="get-in-touch-dialog-content">
        <Typography>
          I thought about putting an email form here, but I have other channels already:
        </Typography>
        <div id="channels-wrapper">
          {CHANNELS.map(channel => 
            (
                <Link 
                  href={channel.link}
                  target="_blank"
                  className="channel-box-wrapper"
                  key={channel.link}
                >
                  {channel.icon}
                  <Typography variant="body2">{channel.name}</Typography>
                </Link>
            )  
          )}
        </div>
      </DialogContent>
      <DialogActions id="get-in-touch-dialog-actions">
        <Button
          id="go-back-button"
          variant="contained"
          color="primary"
          onClick={props.onClose}  
        >
          <Typography>Go back</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}